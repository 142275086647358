.right_btn[data-v-6a07fb5e] {
  text-align: right;
  margin-bottom: 10px;
}
.table-box[data-v-6a07fb5e] {
  font-size: 13px;
}
.table-header[data-v-6a07fb5e] {
  display: flex;
  height: 34px;
}
.table-header span[data-v-6a07fb5e] {
  display: block;
  width: 150px;
  height: 34px;
  padding: 0 8px;
  color: #fff;
  line-height: 34px;
  background-color: var(--themeColor, #17a2b8);
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
  box-sizing: border-box;
}
.table-header span[data-v-6a07fb5e]:first-of-type {
  width: 55px;
  border-left: 1px solid #b9b9b9;
}
.table-row[data-v-6a07fb5e] {
  display: flex;
}
.table-row > div[data-v-6a07fb5e] {
  display: flex;
  align-items: center;
  width: 150px;
  height: 34px;
  padding: 0 8px;
  box-sizing: border-box;
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
}
.table-row > div[data-v-6a07fb5e]:first-of-type {
  width: 55px;
  border-left: 1px solid #b9b9b9;
}
.table-row.active > div[data-v-6a07fb5e] {
  background-color: #ecf7f9;
}